import React, { useState, useEffect } from "react";
import axios from "axios";
import { backend_url } from "../App";
import {
  IoChevronBackCircleOutline,
  IoChevronForwardCircleOutline,
} from "react-icons/io5";

const CarouselComponent = () => {
  const [active, setActive] = useState(0);
  const [data, setData] = useState([]);

  const CARDS = data.length;
  const MAX_VISIBILITY = data.length;
  const count = CARDS;

  useEffect(() => {
    const options = {
      method: "GET",
      url: `${backend_url}/testimony/get-all-testimonial`,
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        setData(response.data); // Assuming testimonials is the key holding your testimonial data
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  return (
    <>
      {data?.length > 0 && (
        <div className="flex w-[95%] items-center justify-center overflow-hidden bg-gradient-to-br font-montserrat relative m-auto">
          {active > 0 && (
            <IoChevronBackCircleOutline
              className="absolute left-1 sm:left-[50px] top-1/2 transform -translate-y-1/2 text-[#15803D] text-4xl sm:text-5xl z-40 cursor-pointer"
              onClick={() => setActive((i) => i - 1)}
            />
          )}
          <div className="relative w-[300px] sm:w-[500px] md:w-[600px] lg:w-[700px] h-80 perspective-500 transform-style-preserve-3d pt-2 sm:py-10">
            {data.map((item, i) => (
              <div
                key={i}
                className="absolute w-full h-full transform transition-transform duration-300"
                style={{
                  "--offset": (active - i) / 2,
                  "--abs-offset": Math.abs(active - i) / 2,
                  "--direction": Math.sign(active - i),
                  "--active": i === active ? 1 : 0,
                  transform: `
              rotateY(calc(var(--offset) * 50deg))
              scaleY(calc(1 + var(--abs-offset) * -0.4))
              translateZ(calc(var(--abs-offset) * -30rem))
              translateX(calc(var(--direction) * -5rem))
            `,
                  filter: `blur(calc(var(--abs-offset) * 1rem))`,
                  pointerEvents: active === i ? "auto" : "none",
                  opacity: Math.abs(active - i) >= MAX_VISIBILITY ? "0" : "1",
                  display:
                    Math.abs(active - i) > MAX_VISIBILITY ? "none" : "block",
                  zIndex: i === active ? 30 : 1, // Set z-index of active card to 50, others to 1
                }}
              >
                <div className="w-full h-full px-2 sm:p-8 bg-white rounded-lg  shadow-inner">
                  <div className="flex flex-col sm:flex-row gap-5  sm:items-center">
                    <img
                      src={item.testimonial_image}
                      alt="people"
                      className="h-36 sm:h-16 w-36 sm:w-16 m-auto  sm:m-0 rounded-full  " // Changed from rounded-[64px] to rounded-full
                    />
                    <div>
                      <h1 className="text-xl text-black font-medium">
                        {/* <span className="font-semibold">Name : </span>{" "} */}
                        {item.person_name}
                      </h1>
                      <h1 className="text-base font-md text-gray-500">
                        {" "}
                        {/* <span className="font-semibold">Company : </span>{" "} */}
                        {item.company}
                      </h1>
                      {/* <p>
                    {" "}
                    <span className="font-semibold">Description : </span>{" "}
                    {item.designation}
                  </p> */}
                      <div className="flex gap-3">
                        {typeof item.rating === "number" ||
                        !isNaN(Number(item.rating)) ? (
                          <p>
                            {" "}
                            {/* <span className="font-semibold">Rating : </span> */}
                            {(() => {
                              const rating =
                                typeof item.rating === "string"
                                  ? parseInt(item.rating, 10)
                                  : item.rating;
                              const stars = [];
                              for (let i = 0; i < rating; i++) {
                                stars.push(<span key={i}>⭐</span>);
                              }
                              return stars;
                            })()}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="sm:mt-6">
                    <h1>{item.title}</h1>
                    <p> "{item.content}"</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {active < count - 1 && (
            <IoChevronForwardCircleOutline
              className="absolute right-1  sm:right-[50px] top-1/2 transform -translate-y-1/2 text-[#15803D] text-4xl sm:text-5xl z-40 cursor-pointer"
              onClick={() => setActive((i) => i + 1)}
            />
          )}
        </div>
      )}
    </>
  );
};

export default CarouselComponent;
