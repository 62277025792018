import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { backend_url } from "../App";
import axios from "axios";

import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { GoDash } from "react-icons/go";

const Product = () => {
  const { name, id } = useParams();
  const [allCommodities, setAllCommodities] = useState([]);
  const [productLName, setProductLName] = useState();
  const [selectedCommodity, setSelectedCommodity] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);

  const handleProductLName = (ProductName) => {
    setProductLName(ProductName);
  };
  // console.log(name);
  const downloadPdf = () => {
    // Path to your PDF file
    const pdfPath = "/Dry-Ginger-Broucher.pdf";

    // Create a link element
    const link = document.createElement("a");

    // Set its href attribute to the path of the PDF
    link.href = pdfPath;

    // Set the download attribute to force download the file
    link.setAttribute("download", "example.pdf");

    // Append the link to the body
    document.body.appendChild(link);

    // Trigger a click event on the link
    link.click();

    // Clean up: remove the link from the DOM
    document.body.removeChild(link);
  };
  useEffect(() => {
    if (allCommodities.length > 0 && selectedCommodity.length === 0) {
      setSelectedCommodity(allCommodities[0]);
      // console.log(setSelectedCommodity, "setSelectedCommodity");
    }
    window.scrollTo(0, 0);
  }, [selectedCommodity]);

  useEffect(() => {
    getCommodities();
  }, [name]);

  const handleClick = (data) => {
    setSelectedCommodity(data);
  };

  const getCommodities = () => {
    const options = {
      method: "GET",
      url: `${backend_url}/commodity/get-all-commodity`,
    };

    axios
      .request(options)
      .then(function (response) {
        const filteredCommodities = response.data.filter(
          (commodity) => commodity.commodity_category === name
        );

        // Find the commodity with a matching id
        const matchingCommodity = filteredCommodities.find(
          (commodity) => commodity.id === id
        );

        if (matchingCommodity) {
          setSelectedCommodity(matchingCommodity);
          setProductLName(matchingCommodity.commodity_name);
        } else {
          setSelectedCommodity(null); // or handle this scenario appropriately
        }

        setAllCommodities(filteredCommodities);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  const toggleSection = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  function truncateString(str, num) {
    if (str && str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  }

  return (
    <div className="flex flex-col lg:flex lg:flex-row w-full gap-5  p-10 sm:pl-16  sm:pr-16 sm:pt-10 lg:p-5 xl:pl-24 xl:pr-24 xl:p-5 ">
      <div className="w-full lg:w-[80%]  ">
        <h1 className="text-3xl text-green-700 font-medium mt-2 ">
          {selectedCommodity?.commodity_name} :
        </h1>
        <div className="pt-2 w-full ">
          <img
            src={selectedCommodity?.commodity_image}
            alt=""
            className="w-full sm:h-[500px] shadow-md mt-2 object-contain"
          />
        </div>
        <button
          // onClick={() => downloadPdf()}
          className="border-4 border-green-700 px-7 py-3 mt-5 font-semibold text-green-400 text-lg "
        >
          Download Brochure
        </button>
        <p className=" leading-6 mt-2 text-gray-500 text-base font-medium">
          {selectedCommodity?.description}
        </p>

        <div className="mt-2">
          <h3 className="text-lg text-green-700 font-semibold">Gallery :</h3>
          <div className="flex w-full">
            {selectedCommodity && selectedCommodity?.commodity_gallery ? (
              selectedCommodity.commodity_gallery.map((img, index) => (
                <div className="w-full mr-6">
                  <img
                    key={index}
                    src={img}
                    alt=""
                    className="w-full h-[200px] shadow-md mt-2 object-contain bg-gray-50"
                  />
                </div>
              ))
            ) : (
              <p>No images available.</p>
            )}
          </div>
        </div>
        <div className="flex flex-col  mt-5">
          <h3 className="text-lg text-green-700 font-semibold">
            Specification of {selectedCommodity?.commodity_name} :
          </h3>
          {/* <p>{selectedCommodity.specification}</p> */}
        </div>
        <div className="flex flex-col  mt-5">
          <h3 className=" text-lg text-green-700 font-semibold"> Quality :</h3>
          <p className="text-base text-gray-500 text-left">
            {selectedCommodity?.quality}
          </p>
        </div>
        <div className="flex flex-col  mt-5">
          <h3 className=" text-lg text-green-700 font-semibold">
            {" "}
            Packaging Detail :
          </h3>
          <p className="text-base text-gray-500">
            {selectedCommodity?.packaging_details}
          </p>
        </div>
        <div className="flex flex-col mt-5">
          <h3 className="text-lg text-green-700 font-semibold">Our Import :</h3>
          {selectedCommodity && selectedCommodity.countries_importing ? (
            selectedCommodity.countries_importing.map((item, index) => (
              <div key={index}>
                <p className="text-base text-gray-500">{item.value}</p>
              </div>
            ))
          ) : (
            <p>Not available.</p>
          )}
        </div>
        <div className="flex flex-col mt-5">
          <h3 className="text-lg text-green-700 font-semibold">
            {" "}
            Our Export :{" "}
          </h3>
          {selectedCommodity && selectedCommodity.countries_importing ? (
            selectedCommodity.countries_exporting.map((item, index) => (
              <div key={index}>
                <p className="text-base text-gray-500">{item?.value}</p>
              </div>
            ))
          ) : (
            <p>Not available.</p>
          )}
        </div>

        <div className="flex flex-col ">
          <div className="w-full ">
            <h3 className=" text-lg text-green-700 font-semibold my-5">
              Frequently Asked Questions:
            </h3>

            {selectedCommodity && selectedCommodity.FAQ ? (
              selectedCommodity.FAQ.map((section, index) => (
                <div key={index} className="mb-2 ">
                  <button
                    className={`w-full text-base text-left bg-[#f7f4f4] p-3  transition duration-300 ${
                      activeIndex === index ? "bg-[#f7f4f4]" : ""
                    }`}
                    onClick={() => toggleSection(index)}
                  >
                    <div className="flex justify-between">
                      {section?.question}
                      {activeIndex === index ? (
                        <MdOutlineKeyboardArrowUp className="text-2xl" />
                      ) : (
                        <MdOutlineKeyboardArrowDown className="text-2xl" />
                      )}
                    </div>
                  </button>

                  <div
                    className={`bg-white transition-all duration-300 ease-in-out overflow-hidden ${
                      activeIndex === index ? "max-h-full p-3" : "max-h-0"
                    }`}
                    style={{
                      maxHeight: activeIndex === index ? "1000px" : "0",
                      transition: "max-height ",
                    }}
                  >
                    <p className="text-base">{section?.answer}</p>
                  </div>
                </div>
              ))
            ) : (
              <p>No FAQ available.</p>
            )}
          </div>
        </div>
      </div>

      <div className="w-full lg:w-[25vw]  pt-14 text-left">
        <ul className="flex flex-col gap-3">
          {allCommodities.map((data) => (
            <button onClick={() => handleClick(data)}>
              {" "}
              <li
                key={data.id}
                className={`py-3 bg-gray-200 pl-5 text-[17px] text-start ${
                  productLName === data?.commodity_name
                    ? "border-[#E8D88F]"
                    : "border-green-800"
                } border-l-4 hover:bg-gray-300

                    `}
                onClick={() => handleProductLName(data?.commodity_name)}
              >
                <p className="flex items-center gap-4">
                  {productLName === data?.commodity_name ? (
                    <span className="w-4">
                      <GoDash />
                    </span>
                  ) : (
                    <span className="w-4"></span>
                  )}
                  <span>{truncateString(data?.commodity_name, 30)}</span>
                </p>
              </li>
            </button>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Product;
