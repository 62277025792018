import React, { useEffect, useState } from "react";
import axios from "axios";
import { backend_url } from "../App";
import { Link } from "react-router-dom";

const Products = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = () => {
    const options = {
      method: "GET",
      url: `${backend_url}/category/get-all-category`,
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        setCategories(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  return (
    <>
      <div className="pb-20">
        <div className=" font-semibold text-center flex justify-evenly  mt-20 w-[100%] text-2xl sm:text-4xl">
          <h1>OUR CATAGORIES RANGE</h1>
        </div>

        <div className="flex justify-evenly text-green-500 mt-4 mb-4 text-lg ml-5 sm:text-xl">
          <h1>We are the Major Processors, Suppliers and Exporter</h1>
        </div>
        <br />
        {/* <ImageSlider pics={pics} /> */}
        <div className="">
          <div className="w-[80%] m-auto">
            <div className="grid grid-flow-cols-1 sm:grid-cols-2 lg:grid-cols-3  gap-y-10 m-auto">
              {categories.map((category) => (
                <div
                  key={category.id}
                  className="w-auto flex flex-col justify-center items-center"
                >
                  <Link
                    to={`/sub_products/${category.category_name}`}
                    className="group"
                  >
                    <img
                      class="w-56 h-56 rounded-full inset-0 opacity-100 border-2 border-dashed border-green-500 border-opacity-0 group-hover:border-opacity-100 hover:opacity-50 transition-opacity  overflow-hidden p-1"
                      src={category.category_image_url}
                      alt="indian-spices-rev1rev"
                    />
                  </Link>
                  <p className="text-center text-black font-md mt-3 text-xl">
                    {category.category_name}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
